import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Layout from '../../components/layout';
import ProductCards from '../../components/product-cards/ProductCards';
import SEO from '../../components/seo';
import theme from '../../constants/theme/theme';
import AngledSection from '../../components/layout/AngledSection';
import DemoCallout from '../../components/demo/DemoCallout';

const Products = () => {
	return (
		<Layout>
			<SEO title="Our Products" description="Software and applications for dry stack marinas and boat clubs available from SpeedyDock." />
			<AngledSection
				color={theme.palette.secondary.dark}
				isAngledTop={false}
			>
				<Box pb={8} pt={16} bgcolor="secondary.dark" color="primary.main">
					<Container>
						<Typography variant="h3" component="h1" gutterBottom>
							Our Products
						</Typography>
						<Typography variant="subtitle1" component="div" gutterBottom>
							Looking for online dry stack boat launch scheduling software, boat club reservation scheduling software, or an e-commerce integration for your marina or boat dealership? View our products to learn more about how SpeedyDock can help.
						</Typography>
					</Container>
				</Box>
			</AngledSection>
			<Box pb={8} pt={3} bgcolor="#FFF" color="primary.main">
				<Container>
					<Box pt={4}>
						<ProductCards />
					</Box>
				</Container>
			</Box>
			<DemoCallout bgColor="secondary.dark" />
		</Layout>
	);
};

export default Products;
